import { $themeBreakpoints } from '@themeConfig'
import axios from '@/libs/axios'
import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
    showLoader: false,
    turnstileSiteKey: ''
  },
  getters: {
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    }
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
    START_LOADING(state) {
      state.showLoader = true
    },
    STOP_LOADING(state) {
      state.showLoader = false
    },
    SET_TURNSTILE_SITE_KEY(state, siteKey) {
      state.turnstileSiteKey = siteKey
    }
  },
  actions: {
    async setTurnstileSiteKey({commit}) {
      try {
        const response = await axios.get('/api/public/v1/turnstile_site_key')
        commit('SET_TURNSTILE_SITE_KEY', response.data.turnstile_site_key)
      } catch (error) {
        Vue.prototype.$printError('Prišlo je do napake pri pridobivanju podatkov')
      }
    }
  }
}
