import Vue from 'vue'
import store from '@/store'
import router from '@/router'

// axios
import axios from 'axios'

const axiosIns = axios.create({
    // You can add your headers here
    // ================================
    // baseURL: 'https://some-domain.com/api/',
    // timeout: 1000,
    // headers: {'X-Custom-Header': 'foobar'}
})

axiosIns.interceptors.response.use(
    response => response,
    error => {
        if (error.response.status === 401) {
            store.dispatch('user/setLogout').then()
            if (router.currentRoute.name !== 'login') {
                router.push({name: 'login'}).then()
            }
        }
        return Promise.reject(error)
    }
)

axiosIns.interceptors.request.use(
    config => {
        const jwt = localStorage.getItem('accessToken')
        if (jwt && jwt !== '') config.headers.Authorization = `Bearer ${jwt}`
        return config
    },
    error => Promise.reject(error)
)


Vue.prototype.$http = axiosIns

export default axiosIns
