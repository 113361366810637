import Vue from 'vue'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import updateLocale from 'dayjs/plugin/updateLocale'
import localeSl from 'dayjs/locale/sl'
dayjs.extend(updateLocale)
dayjs.extend(utc)
dayjs.locale(localeSl)

Vue.prototype.$dayjs = dayjs

export default dayjs
