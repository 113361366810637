export default {
  namespaced: true,
  state: {
    loggedIn: false,
    userData: localStorage.getItem('userData') || null
  },
  getters: {
    getUserData(state) {
      return state.userData
    }
  },
  mutations: {
    SET_LOG_IN(state, payload) {
      state.loggedIn = true
      state.userData = JSON.parse(payload)
    },
    SET_LOG_OUT(state) {
      state.loggedIn = false
    },
    REMOVE_USER_DATA(state) {
      state.userData = null
    }
  },
  actions: {
    setLogin({ commit }, userData) {
      commit('SET_LOG_IN', JSON.stringify(userData))
      localStorage.setItem('loggedIn', 'true')
      localStorage.setItem('userData', JSON.stringify(userData))
    },
    setLogout({ commit }) {
      commit('SET_LOG_OUT')
      commit('REMOVE_USER_DATA')
      localStorage.setItem('loggedIn', 'false')
      localStorage.removeItem('userData')
      localStorage.removeItem('accessToken')
    },
    // eslint-disable-next-line no-unused-vars
    setTokenString({ commit }, tokenString) {
      localStorage.setItem('accessToken', tokenString)
    }
  }
}
