import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import router from './router'
import store from './store'
import App from './App.vue'
// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/dayjs'
import {localize} from 'vee-validate'
localize('sl')


// font awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import {fas} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

//FontAwesome
library.add(fas)
Vue.component('fa', FontAwesomeIcon)

// axios
import '@/libs/axios'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

require('@core/assets/fonts/feather/iconfont.css') // For form-wizard


Vue.config.productionTip = false

Vue.prototype.$printError = function(error) {
  this.$toast({
    component: ToastificationContent,
    props: {
      title: 'Napaka',
      icon: 'DangerOctagonIcon',
      text: error,
      variant: 'danger'
    }
  })
}

Vue.prototype.$printWarning = function(message) {
  this.$toast({
    component: ToastificationContent,
    props: {
      title: 'Opozorilo',
      icon: 'AlertTriangleIcon',
      text: message,
      variant: 'warning'
    }
  })
}

Vue.prototype.$printSuccess = function(message) {
  this.$toast({
    component: ToastificationContent,
    props: {
      title: 'Uspešno',
      icon: 'CheckCircleIcon',
      text: message,
      variant: 'success'
    }
  })
}

Vue.prototype.$confirmDialog = function(message, yesButtonMessage = 'Da', noButtonMessage = 'Prekliči') {
  return this.$bvModal.msgBoxConfirm(message, {
    okVariant: 'primary',
    cancelVariant: 'outline-primary',
    okTitle: yesButtonMessage,
    cancelTitle: noButtonMessage,
    hideHeaderClose: false,
    centered: true
  })
}


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
