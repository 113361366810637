import Vue from 'vue'
import VueRouter from 'vue-router'
import authService from '../auth/authService.js'
import store from '../store/index.js'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/AddSubmissionView.vue'),
      meta: {
        layout: 'full'
      }
    },
    {
      path: '/testiranje',
      name: 'testing',
      component: () => import('@/views/AddSubmissionView.vue'),
      meta: {
        layout: 'full'
      }
    },
    {
      path: '/admin',
      redirect: '/admin/vloge',
      meta: {
      }
    },
    {
      path: '/admin/vloge',
      name: 'submissions',
      component: () => import('@/views/admin/submission/Submissions.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/admin/vloge/duplikati',
      name: 'duplicates',
      component: () => import('@/views/admin/duplicates/Duplicates.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/admin/vloga/:id',
      name: 'submission',
      component: () => import('@/views/admin/submission/Submission.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/admin/termini',
      name: 'terms',
      component: () => import('@/views/admin/term/Terms.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/admin/obcine',
      name: 'municipalities',
      component: () => import('@/views/admin/municipality/Municipalities.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/admin/uporabniki',
      name: 'users',
      component: () => import('@/views/admin/user/Users.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/admin/nacini_potovanja',
      name: 'submission_types',
      component: () => import('@/views/admin/submission/SubmissionTypes.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/admin/zdravniki',
      name: 'doctors',
      component: () => import('@/views/admin/doctors/Doctors.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/admin/osnovne_sole',
      name: 'primary_school',
      component: () => import('@/views/admin/primarySchools/PrimarySchools.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/admin/premaknjene_vloge',
      name: 'moved_submissions',
      component: () => import('@/views/admin/movedSubmissions/MovedSubmissions.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/admin/ustanove',
      name: 'institutions',
      component: () => import('@/views/admin/institutions/Institutions.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/prijava',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full'
      }
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full'
      }
    },
    {
      path: '*',
      redirect: 'error-404'
    }
  ]
})

router.beforeEach((to, from, next) => {

  const loggedIn = localStorage.getItem('loggedIn')
  if (loggedIn === 'true') {
    store.state.user.loggedIn = true
    const userData = localStorage.getItem('userData')
    if (userData) {
      store.state.user.userData = JSON.parse(userData)
    }
  }

  if (!authService.isAuthenticated() && to.meta.requiresAuth) {
    store.dispatch('user/setLogout').then()
    return next({name: 'login'})
  }

  next()
})


// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
